























import './styles/DocumentFilesPopup.scss';
import { Component, Watch, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { PopupActions } from '@store/Popup';
import { ITogglePopupPayload } from '@/interfaces/ITogglePopupPayload';
import { ProjectDocumentsActions } from '@store/modules/project-documents/Types';
import { IConstructDocument } from '@store/modules/work-list/interfaces/Interfaces';
import { IWorkListPopupsState } from '@store/modules/work-list/modules/popups/Interfaces';

const NSProject = namespace('storeProject');
const NSWorkList = namespace('storeWorkList');
const NSDocuments = namespace('storeProjectDocuments');
const NSWorkListPopups = namespace('storeWorkList/storeWorkListPopups');

@Component({
   name: 'DocumentFilesPopup',

   components: {
       BasePopup: () => import('@components/popups/BasePopup.vue'),
       FileCard: () => import('./FileCard.vue'),
   },
})
export default class DocumentFilesPopup extends Vue {
    $refs!: { basePopup: any }

    @NSProject.Getter('projectId') projectId!: number;
    @NSWorkList.Getter('viewingDocument') viewingDocument!: IConstructDocument;
    @NSWorkListPopups.Getter('documentFilesPopupToggler') documentFilesPopupToggler!: boolean;
    @NSWorkListPopups.Action(PopupActions.A_TOGGLE_POPUP)
    togglePopup!: (
        payload: ITogglePopupPayload<IWorkListPopupsState>,
    ) => void;
    @NSDocuments.Action(ProjectDocumentsActions.A_GET_DOC_FILES) getDocFiles!:
        (payload: { appId: number, documentId: string }) => Promise<any>;

    files: any[] = [];

    @Watch('documentFilesPopupToggler')
    watchPopupOpen() {
        if (this.documentFilesPopupToggler) this.openPopup();
        else this.$refs.basePopup.closePopup();
    }

    openPopup() {
        this.getDocFiles({
            appId: this.projectId,
            documentId: this.viewingDocument.uuid,
        }).then((files) => {
            this.files = files;
        }).finally(() => {
            this.$refs.basePopup.openPopup();
        });
    }

    closePopup() {
        this.togglePopup({
            popupToggler: 'documentFilesPopupToggler',
            state: false,
        });
    }
}
